/* You can add global styles to this file, and also import other style files */

.modal-large .modal-content {
	width: auto !important;
	max-width: 100% !important;
	max-height: 80vh;
}

.modal-dialog-l {
	width: auto !important;
	min-width: 600px !important;
	max-width: 100% !important;
	max-height: 80vh
}

.modal-content {
	overflow-y: auto !important;
}

.dp-hidden {
	width: 0;
	margin: 0;
	border: none;
	padding: 0;
}

.custom-day {
	text-align: center;
	padding: 0.185rem 0.25rem;
	display: inline-block;
	height: 2rem;
	width: 2rem;
}

.no-border tbody {
	border-style: unset;
}

.small-footer div.modal-content, div.modal-footer {
	min-height: 0;
	border-top: 0 !important;
}

.custom-day.focused {
	background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
	background-color: var(--orange-500);
}

.custom-day.faded {
	background-color: rgba(254, 231, 214, 255);
}

.ngb-dp-navigation-chevron {
	color: var(--orange-500);
}

.ngb-dp-weekday {
	color: black !important;
	background-color: white !important;
}

.ngb-dp-weekdays {
	background-color: white !important;
}

.ngb-dp-header {
	background-color: white !important;
}

.ngb-dp-month-name {
	background-color: white !important;
}

.no-scroll .modal-content {
	overflow-y: hidden;
}

.ngb-dp-arrow-btn {
	min-width: initial !important;
}

.btn-download {
	background: none;
	width: auto !important;
}

.mt-7r {
	margin-top: 0.67rem !important;
}

.input-number {
	align-content: center;
	border: 1px solid var(--grey-100) !important;
	border-radius: 8px !important;
	background-color: #F8F9FA !important;
	height: 38px !important;
	color: var(--darkblue-700) !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	width: 60px !important;
}

.offset-tooltip-body {
	color: lightgray;
}

.offset-tooltip .tooltip-inner {
	background-color: var(--darkblue-800);
	text-align: left;
}

.offset-tooltip.bs-tooltip-end .tooltip-arrow::before {
	border-right-color: var(--darkblue-800);
}

.offset-tooltip.bs-tooltip-start .tooltip-arrow::before {
	border-left-color: var(--darkblue-800);
}

.offset-tooltip.bs-tooltip-top .tooltip-arrow::before {
	border-top-color: var(--darkblue-800);
}

.offset-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
	border-bottom-color: var(--darkblue-800);
}

hr {
	border-color: var(--grey-900) !important;
}

.bottom-5 {
	bottom: 5%;
}

.search {
	position: relative;
}

.search i {
	position: absolute;
	left: 10px;
	top: 11px;
	color: gray;
}

.search-input {
	padding-left: 30px !important;
}

td .search .search-input {
	padding-left: 16px !important;
}

td .search .search-input:not(:placeholder-shown) ~ i,
td .search .search-input:focus ~ i {
	display: none;
}

td > app-search > div > input.search-input {
	width: 100% !important;
	min-width: 100px;
}

.sidebar {
	max-width: 290px;
}

.shadow,
.hover-shadow:hover {
	box-shadow: 0 0.25rem 0.5rem rgba(31, 57, 109, 0.1), 0 0.5rem 1rem rgba(31, 57, 109, 0.1) !important;
}

.form-control[readonly]:focus, .form-control[readonly]:active,
.input-number[readonly]:focus {
	border: 1px solid var(--grey-100) !important;
	outline: none;
}

.form-control.form-control-small {
	width: 200px !important;
}

.ng-invalid ~ .invalid-feedback {
	display: block;
}

.multiselect-dropdown {
	width: 364px !important;
	position: relative;
	display: block;
}

.dropdown-btn {
	border: 1px solid var(--grey-100);
	border-radius: 8px !important;
	background-color: #F8F9FA !important;
	width: 364px !important;
	height: 38px !important;
	padding: 12px 16px !important;
	color: var(--darkblue-700) !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	line-height: 16px !important;
	display: inline-block;
}

.dropdown-list {
	border: 0px !important;
}

.multiselect-item-checkbox input[type='checkbox'] + div:before {
	color: #FFFFFF !important;
	width: 20px !important;
	height: 20px !important;
	border: 1px solid rgba(0, 0, 0, 0.25) !important;
	border-radius: 0.25em !important;
	margin-top: -12px !important;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
	width: 11px !important;
	height: 4px !important;
	margin-top: -7px !important;
}

.selected-item-container {
	margin-top: -3px;
}

.cursor-default {
	cursor: default;
}

.fill-remaining {
	width: 230px;
}

.sorting {
	width: 500px;
}

.small-font {
	font-size: 12px !important;
}

.active-sorting {
	background-color: red;
}

.custom-row {
	height: 80px !important;
}

.badge-bg-orange {
	background-color: #fff3cd;
	color: #664d03;
}

.badge-bg-red {
	background-color: #f8d7da !important;
	color: #842029 !important;
}

.badge-bg-green {
	background-color: #d1e7dd;
	color: #0f5132;
}

.badge-bg-blue {
	background-color: #cff4fc;
	color: #055160;
}

.center {
	position: absolute;
	left: 50%;
	top: 50%;
}

.comment-input {
	width: 700px !important;
	height: 120px !important;
	min-width: 600px !important;
	min-height: 120px !important;
	resize: none;
}

.small-comment {
	font-size: 12px;
}

.dropbtn {
	font-size: 16px;
	border: none;
	background-color: inherit;
}

.dropbtn-dropdown {
	display: inline-block;
}

.dropdown-content {
	display: none;
	position: fixed;
	min-width: 224px;
	z-index: 10 !important;
	border: 1px solid rgba(230, 230, 230, 1);
	right: 10px;
}

.dropdown-content a {
	padding: 12px 16px;
	display: block;
	background-color: white;
}

.dropdown-content-visible {
	display: block;
}

.top-10 {
	top: 3px;
}

.custom-separator {
	border: 1px solid rgba(230, 230, 230, 1) !important;
}
